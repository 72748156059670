<script lang="ts" setup> 

    const img = useImage()

    const props = defineProps({ 
        blok: Object 
    })

    const imageCheck = (img:string) => {
        return img.split('.').pop() || ''
    }

    const width = (value:string) => {
        return value.split('/')[5].split('x')[0]
    }

    const height = (value:string) => {
        return value.split('/')[5].split('x')[1]
    }

    const richtext = (value:any) => renderRichText(value) 

</script>

<template>
   <div v-editable="blok" class="grid grid-col-1" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-6 lg:gap-8">

            <div v-if="blok?.heading || blok?.subheading || blok?.richtext && richtext(blok?.richtext)" class="flex flex-col gap-4 justify-center items-center text-center max-w-[900px] mx-auto">
                <div v-if="blok?.heading || blok?.subheading" class="flex flex-col gap-2 justify-center items-center text-center">
                    <component
                        v-if="blok && blok?.heading" 
                        :is="`headings-${blok?.heading_tag || 'h3'}`" 
                        :value="blok?.heading"
                        :classes="blok?.heading_style || 'h2'" 
                    />
                    <component
                        v-if="blok && blok?.subheading" 
                        :is="`headings-${blok?.subheading_tag || 'h4'}`" 
                        :value="blok?.subheading"
                        :classes="blok?.subheading_style || 'h3'"
                    />
                </div>
                <div v-if="blok?.richtext && richtext(blok?.richtext)" v-html="richtext(blok?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500"/>
            </div>

            <div 
                v-if="blok?.cards && blok?.cards.length > 0"
                class="grid lg:grid-cols-2 gap-2"
            >
                <div v-for="card in blok.cards" :key="card._uid" v-editable="card" class="grid grid-col-1 m-0  px-4 md:px-6 lg:px-8 pt-6 md:pt-6 lg:pt-8 rounded-1 border border-mystic-200" :class="[card?.theme || 'dark', card?.backgroundColor || 'bg-porcelain-100']">
                    <div class="flex flex-col gap-4">
                        <div class="flex flex-col gap-2 justify-center items-center text-center">
                            <span v-if="card?.heading" v-html="card.heading" class="font-hass-display text-h3_mobile md:text-h3_tablet lg:text-h3_desktop font-medium [&.light]:text-white-50 dark:text-elephant-950 [&>em]:not-italic [&>em]:text-lima-600" :class="[card?.theme || 'dark']"/>
                            <span v-if="card?.subheading" v-html="card.subheading" class="font-hass-display text-h5_mobile md:text-h5_tablet lg:text-h5_desktop font-medium [&.light]:text-white-50 dark:text-nevada-500 text-center" :class="[card?.theme || 'dark']"/>
                        </div>
                        <div v-if="card?.richtext" v-html="richtext(card?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal [&.light]:text-white-50 dark:text-nevada-500 [&>*>a]:text-lima-600 [&>*>a]:underline text-center" :class="[card?.theme || 'dark']"/>
                        <div v-if="card?.buttons && card?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center items-center text-center">
                            <StoryblokComponent v-for="button in card.buttons" :key="button._uid" :blok="button" />
                        </div>

                        <template v-if="card?.image && card?.image?.filename && imageCheck(card?.image?.filename) === 'svg'">
                            <NuxtImg
                                v-if="card?.image && card?.image?.filename"
                                provider="storyblok"
                                width="530"
                                height="270"
                                :src="card?.image?.filename"
                                :alt="card?.image?.alt || card?.image?.title || '#'" 
                                :fetchpriority="'auto'"
                                :decoding="'async'"
                                :loading="'lazy'"
                                class="w-full h-full max-h-fit lg:max-h-[270px] mt-auto"
                            />
                        </template>
                        <template v-else>
                            <NuxtImg
                                v-if="card?.image && card?.image?.filename"
                                provider="storyblok"
                                :width="width(card?.image?.filename)"
                                :height="height(card?.image?.filename)" 
                                :sizes="`xs:100vw sm:${width(card?.image?.filename)} md:${width(card?.image?.filename)} narrow:${width(card?.image?.filename)} lg:${width(card?.image?.filename)} xl:${width(card?.image?.filename)} 1xl:${width(card?.image?.filename)} normal:${width(card?.image?.filename)} 2xl:${width(card?.image?.filename)} 3xl:${width(card?.image?.filename)} wide:${width(card?.image?.filename)}`"
                                quality="75"
                                fit="in"
                                :placeholder="[width(card?.image?.filename), height(card?.image?.filename), 10, 5]"
                                :src="card?.image?.filename"
                                :alt="card?.image?.alt || card?.image?.title || '#'" 
                                :fetchpriority="'auto'"
                                :decoding="'async'"
                                :loading="'lazy'"
                                class="w-full h-auto mt-auto"
                            />
                        </template>
                    </div>
                </div>
            </div>

            <div v-if="blok?.buttons && blok?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center items-center text-center">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>

        </div>
   </div>
</template>